.header-wrapper {
  // display: none !important;
  padding: 5px 100px;
  height: 80px;
  background: #fff;
  display: flex;
  flex-direction: row;
  //justify-content: flex-end;
  justify-content: space-between;
  //   margin-right: 150px;
  
  .headerLogo {
    display: flex;
    &_imgLG {
      img,svg {
        width: 85px;
        height: 35px;
      }
    }
  };
  .headerContent {
    p {
      display: flex;
      justify-content: center;
      margin: 0
    };
    &_logout {
      cursor: pointer;
    }
  }
}
