.commonIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 2em;
  }
}
.active {
}
