.public {
  // .login,
  .home,
  .about {
    width: 93vw;
    height: 97vh;
    display: grid;
    grid-template-rows: 50px auto 200px;
    grid-template-areas:
      "header"
      "form"
      "footer";
    align-items: center;
    margin: 0vh 5vw;

    &_header {
      grid-area: header;
    }
  }
}
